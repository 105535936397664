import "./App.css";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Carousel from "react-bootstrap/Carousel";
import CodecademyCourse from "./assets/CodecademyCourse.png";
import CodecademyHome from "./assets/CodecademyHome.png";
import CodecademyModule from "./assets/CodecademyModule.png";
import CodingameEditorPage from "./assets/CodingameEditorPage.png";
import CodingameModulePage from "./assets/CodingameModulePage.png";
import CodingamePrototypePage from "./assets/CodingamePrototypePage.png";
import Comparison1 from "./assets/Comparison1.jpg";
import Comparison2 from "./assets/Comparison2.jpg";
import Comparison3 from "./assets/Comparison3.jpg";
import DesignGuide from "./assets/DesignGuide.jpg";
import DuolingoHome from "./assets/DuolingoHome.png";
import DuolingoMentor from "./assets/DuolingoMentor.png";
import DuolingoModule from "./assets/DuolingoModule.png";
import DuolingoProject from "./assets/DuolingoProject.png";
import DuolingoPrompt from "./assets/DuolingoPrompt.png";
import DuolingoQuestion from "./assets/DuolingoQuestion.png";
import DuolingoQuestion2 from "./assets/DuolingoQuestion2.png";
import DuolingoSchedule from "./assets/DuolingoSchedule.png";
import DuolingoTask from "./assets/DuolingoTask.png";
import FinalHome from "./assets/FinalHome.png";
import FinalLesson from "./assets/FinalLesson.png";
import FinalMentor from "./assets/FinalMentor.png";
import FinalModule from "./assets/FinalModule.png";
import FinalProject from "./assets/FinalProject.png";
import FinalPrompt from "./assets/FinalPrompt.png";
import FinalSchedule from "./assets/FinalSchedule.png";
import KhanHome from "./assets/KhanHome.png";
import KhanModule from "./assets/KhanModule.png";
import KhanSection from "./assets/KhanSection.png";
import OfficialHome from "./assets/OfficialHome.png";
import { CardGroup } from "react-bootstrap";

function App() {
  return (
    <div className="App">
      <Navbar expand="lg" fixed="top">
        <Container>
          <Navbar.Brand href="#introduction">
            <b>Iterative Design Project</b>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#introduction">Introduction</Nav.Link>
              <Nav.Link href="#initial-sketch">Initial Sketch</Nav.Link>
              <Nav.Link href="#final-sketch">Final Sketch</Nav.Link>
              <Nav.Link href="#initial-prototype">Initial Prototype</Nav.Link>
              <Nav.Link href="#initial-feedback">Initial Feedback</Nav.Link>
              <Nav.Link href="#final-prototype">Final Prototype</Nav.Link>
              <Nav.Link href="#final-feedback">Final Feedback</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="parent">
        <section className="section" id="introduction">
          <Container>
            <p className="heading">Introduction</p>
            <p id="intro-text">
              This CSCI 1300 project asks students to iteratively design a
              mobile, website, or desktop application for a startup of their
              choice.
            </p>
            <br />
            <p id="intro-text">
              Our team chose Avocademy, a startup founded in 2020 dedicated to
              teaching people UI/UX design. They offer an eight-week
              personalized UI/UX curriculum, mentoring services, and
              portfolio-building support. We chose this startup because we are
              all current students in a UI/UX course at Brown, which allows us
              to integrate our perspectives into the project.
            </p>
            <br />
            <p id="intro-text">
              We continually iterated on our design for the Avocademy user
              experience, initially sketching out a variety of possible
              experiences before honing in on a final design to produce an
              interactive Figma prototype for. After we received feedback during
              a critique session and through user testing, we further refined
              our prototype to ultimately show to the company.
            </p>
            <Container>
              <div className="d-flex justify-content-center">
                <img
                  src={OfficialHome}
                  id="official-home"
                  alt="A shot of Avocademy's official website."
                />
              </div>
              <p className="alt-text">A photo of the company's website.</p>
            </Container>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="initial-sketch">
          <Container>
            <p className="heading">Initial Sketches</p>
            <p>
              Our first prototype was inspired by language-learning site
              Duolingo. It features a simple, organized structure with a focus
              on navigation elements that allow the user to select course topics
              by theme and level of expertise. There are also workflows to set
              up a meeting with a mentor and to look through project ideas in a
              similar manner.
            </p>
            <br />
            <p>
              The below screens show a mockup of the homepage, module selection
              page, topic selection page within a given module, two example
              questions, the mentor meeting scheduling workflow, and the project
              ideas workflow.
            </p>
            <div className="d-flex justify-content-center">
              <Carousel
                variant="dark"
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                  width: "100%",
                }}
              >
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={DuolingoHome} alt="First slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={DuolingoModule} alt="Second slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={DuolingoTask} alt="Third slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={DuolingoQuestion} alt="Fourth slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={DuolingoQuestion2} alt="Fifth slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={DuolingoMentor} alt="Sixth slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={DuolingoSchedule} alt="Seventh slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={DuolingoProject} alt="Eighth slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={DuolingoPrompt} alt="Ninth slide" />
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </Container>
          <Container>
            <p>
              Our second prototype was inspired by programming puzzle site
              CodinGame. It features a set of puzzles at different difficulty
              levels that the user can complete to earn XP. The puzzles can be
              chosen from a variety of categories, and the user can work on them
              in an integrated editor.
            </p>
            <br />
            <p>
              The below screens show a mockup of the puzzle selection page,
              editor page for a particular puzzle, and prototyping page for a
              particular puzzle.
            </p>
            <div className="d-flex justify-content-center">
              <Carousel
                variant="dark"
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={CodingameModulePage}
                      alt="First slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={CodingameEditorPage}
                      alt="Second slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={CodingamePrototypePage}
                      alt="Third slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </Container>
          <Container>
            <p>
              Our third prototype was inspired by Khan Academy, a popular
              platform for learning a variety of different subjects. The
              homepage features a persistent informative header with account
              information, along with a scrollable display of lesson modules on
              a given topic. Clicking on one of these module cards opens the
              Module View, which gives more detail on current progress on the
              different topics that are a part of the module. Clicking on one of
              these topics opens the Topic View, where the user can read the
              topic description and jump directly into the lesson.
            </p>
            <br />
            <p>
              The below screens show a mockup of the homepage, module view, and
              topic view for an activity of a module.
            </p>
            <div className="d-flex justify-content-center">
              <Carousel
                variant="dark"
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={KhanHome}
                      alt="First slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={KhanModule}
                      alt="Second slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={KhanSection}
                      alt="Third slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </Container>
          <Container>
            <p>
              Our fourth prototype was inspired by Codecademy, an online
              platform that offers coding classes for many different languages.
              It features a detailed page for users to keep track of the topics
              they are interested in or working on, and a code editor for doing
              activities.
            </p>
            <br />
            <p>
              The below screens show a mockup of the homepage, course selection
              page, and editor page for an activity of a module.
            </p>
            <div className="d-flex justify-content-center">
              <Carousel
                variant="dark"
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={CodecademyHome}
                      alt="First slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={CodecademyCourse}
                      alt="Second slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={CodecademyModule}
                      alt="Third slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="final-sketch">
          <Container>
            <p className="heading">Final Sketch</p>
            <p>
              For our final sketch, we opted to combine elements from each of
              the initial sketches, creating an integrated homepage with aspects
              of all of the sketches and incorporating the meeting and project
              workflows from the Duolingo prototype; the topic selection view
              from the Khan Academy prototype; and the lesson view from the
              CodinGame and Codecademy prototypes.
            </p>
            <br />
            <p>
              The below screens show a mockup of the homepage, the topic
              selection page, an example task, the mentor meeting scheduling
              workflow, and the project ideas workflow.
            </p>
            <div className="d-flex justify-content-center">
              <Carousel
                variant="dark"
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                  width: "100%",
                }}
              >
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={FinalHome} alt="First slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={FinalModule} alt="Second slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={FinalLesson} alt="Third slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={FinalMentor} alt="Fourth slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={FinalSchedule} alt="Fifth slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={FinalProject} alt="Sixth slide" />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img src={FinalPrompt} alt="Seventh slide" />
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="initial-prototype">
          <Container>
            <p className="heading">Initial Prototype</p>
            <p>
              After finalizing our sketch, we developed the following visual
              design guide for our website, shown below, to ensure stylistic
              cohesiveness. The stylized logo was taken from the{" "}
              <a href="https://www.ycombinator.com/companies/avocademy">
                YCombinator listing
              </a>{" "}
              for the startup.
            </p>
            <div className="d-flex justify-content-center">
              <img
                src={DesignGuide}
                alt="Design guide"
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                  width: "25%",
                  border: "solid",
                  borderColor: "black",
                  borderWidth: 3,
                }}
              />
            </div>
            <p>
              After that, we combined our low-fidelity prototype with our design
              guide to produce our initial interactive high-fidelity prototype,
              which is embedded below and can also be accessed{" "}
              <a href="https://www.figma.com/proto/HXYixM5CfPGaaenXeHHSiD/Avocademy-Initial-Prototype?node-id=1%3A7&scaling=scale-down&page-id=0%3A1&starting-point-node-id=1%3A7">
                here
              </a>
              .
            </p>
            <br />
            <div className="d-flex justify-content-center">
              <iframe
                title="Initial prototype"
                style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
                width="50%"
                height="500"
                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FHXYixM5CfPGaaenXeHHSiD%2FAvocademy-Initial-Prototype%3Fnode-id%3D1%253A7%26scaling%3Dscale-down%26page-id%3D0%253A1%26starting-point-node-id%3D1%253A7"
                allowFullScreen
              ></iframe>
            </div>
            <br />
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="initial-feedback">
          <Container>
            <p className="heading">Initial Feedback</p>
            <p>
              During a critique session with our peers, we received the
              following feedback on our high fidelity-prototype:
            </p>
            <ul>
              <li>Usability</li>
              <ul>
                <li>
                  Users would like more styling on the homepage versus old
                  school, blue HTML links
                </li>
                <li>
                  Users would like to have a better understanding of the full
                  curriculum
                </li>
                <li>
                  Users were confused by previous/next buttons on the lesson
                  page going to different courses than expected
                </li>
              </ul>
              <li>Suggested minor changes</li>
              <ul>
                <li>Use term submit instead of continue on scheduling page</li>
                <li>Show more dates on scheduling page</li>
                <li>Make the profile image in the lesson page smaller</li>
                <li>Make left menu bar on lesson page smaller</li>
                <li>
                  Make the project ideas page scrollable instead of just
                  implying it
                </li>
                <li>Remove the add/remove goal options on the homepage</li>
                <li>
                  Change the yellow outline on the topic selection page to a
                  different color
                </li>
              </ul>
            </ul>
            <p>
              In response to this feedback, we implemented the following
              changes:
            </p>
            <ul>
              <li>
                Redesigned the homepage to be more visual through adding icons
                and progress bars for each course
              </li>
              <li>
                Added a section of the homepage showing the user&apos;s current
                place in the course, which includes a button that in practice
                would lead to a description of the entire curriculum
              </li>
              <li>
                Changed the interaction behavior in the prototype to reduce user
                confusion and ensure mock content matched user expectations
              </li>
              <li>
                Implemented all of the suggested changes in the &quot;suggested
                minor changes&quot; section above
              </li>
            </ul>
            <p>
              The diagrams below showcase the key changes that we made after
              receiving feedback, with the initial version of the prototype
              shown on the right and the final version shown on the left.
            </p>
            <div className="d-flex justify-content-center">
              <Carousel
                variant="dark"
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={Comparison1}
                      alt="First slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={Comparison2}
                      alt="Second slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="d-flex justify-content-center">
                    <img
                      src={Comparison3}
                      alt="Third slide"
                      style={{ width: "50%" }}
                    />
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="final-prototype">
          <Container>
            <p className="heading">Final Prototype</p>
            <p>
              Based on the feedback we received on our initial prototype, we
              implemented the changes from the "Prototype Comparison" section
              and developed the final version of our interactive high-fidelity
              prototype, which is embedded below and can also be accessed{" "}
              <a href="https://www.figma.com/proto/h9kL8DP45ex85JgEiNQ85H/Avocademy-Final-Prototype?page-id=0%3A1&node-id=1%3A7&viewport=24%2C212%2C0.14&scaling=scale-down&starting-point-node-id=1%3A7">
                here
              </a>
              .
            </p>
            <br />
            <div className="d-flex justify-content-center">
              <iframe
                title="Initial prototype"
                style={{ border: "1px solid rgba(0, 0, 0, 0.1)" }}
                width="50%"
                height="500"
                src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fh9kL8DP45ex85JgEiNQ85H%2FAvocademy-Final-Prototype%3Fpage-id%3D0%253A1%26node-id%3D1%253A7%26viewport%3D24%252C212%252C0.14%26scaling%3Dscale-down%26starting-point-node-id%3D1%253A7"
                allowFullScreen
              ></iframe>
            </div>
          </Container>
        </section>
        <hr className="line"></hr>
        <section className="section2" id="final-feedback">
          <Container>
            <p className="heading">Final Feedback</p>
            <p>
              <p className="subheading">Process</p>
            </p>
            <p>
              We conducted user testing on our final prototype with three users
              through the UserTesting platform. The sampled users were all women
              in the United States from age 28-40 who rated themselves as having
              average to advanced web expertise.
            </p>
            <br />
            <p>
              Users were given a prompt that provided context for the prototype,
              and were then asked to complete a series of tasks using the
              prototype.
            </p>
            <br />
            <p>The scenario provided to the users was as follows:</p>
            <ul>
              <li>
                You've signed up for an 8 week bootcamp learning about user
                interfaces and user experiences, with the hopes of getting a job
                in UI/UX afterwards. You're currently on Week 2 of the course,
                and you have been working on the Intro to Bootstrap module.
                Please note that the "webpage" you'll interact with for these
                tasks is not an actual webpage but instead an interactive mockup
                made on prototyping software.
              </li>
            </ul>
            <br />
            <p>The following tasks were given to the users:</p>
            <ol>
              <li>
                Submit your work on the Intro to Bootstrap Basic Tools task you
                were working on earlier.
              </li>
              <li>Set up a meeting with a mentor to discuss UI/UX concepts.</li>
              <li>
                Look at 3 portfolio project ideas related to design principles.
              </li>
            </ol>
            <br />
            <p>
              After attempting each task, users were asked if they were able to
              successfully complete the task and how difficult they found it.
              After attempting all of the tasks, users were asked to give
              feedback related to their frustrations with the website,
              improvements they would like to make, things they liked about the
              website, and how likely they would be to recommend it to a friend
              or colleague.
            </p>
            <br />
            <p>The following post-test questions were asked to the users:</p>
            <p>
              <ul>
                <li>What frustrated you most about this site?</li>
                <li>
                  If you had a magic wand, how would you improve this site?
                </li>
                <li>What did you like about the site?</li>
                <li>
                  How likely are you to recommend this site to a friend or
                  colleague (0=Not at all likely, and 10=Very likely)?
                </li>
              </ul>
            </p>
            <br />
            <p>
              <p className="subheading">Results</p>
            </p>
            <p>
              Videos of each user's performance on the tasks are shown below:
            </p>
            <CardGroup>
              <Card>
                <iframe
                  title="User 1 video"
                  height="300"
                  src="https://www.youtube.com/embed/dClXKhpQKp4"
                />
                <Card.Body>
                  <u>User 1</u>
                  <ul>
                    <li>
                      User was easily able to navigate to lesson page and submit
                      work
                    </li>
                    <li>
                      User was able to easily navigate to the scheduling page
                      and set up a meeting
                    </li>
                    <li>
                      User was able to easily navigate to the project ideas page
                      and look at project ideas
                    </li>
                    <li>
                      Navigation could be made more intuitive by clearly
                      indicating which page the user would be navigating to when
                      clicking on a link
                    </li>
                    <li>Was in line with expectations!</li>
                  </ul>
                </Card.Body>
              </Card>
              <Card>
                <iframe
                  title="User 2 video"
                  height="300"
                  src="https://www.youtube.com/embed/CKoLWWJvup4"
                />
                <Card.Body>
                  <u>User 2</u>
                  <ul>
                    <li>
                      User was able to navigate to lesson page and submit work,
                      though was not confident that the submission was
                      successful
                    </li>
                    <li>
                      User was able to easily navigate to the scheduling page
                      and set up a meeting
                    </li>
                    <li>
                      User was able to easily navigate to the project ideas page
                      and look at project ideas
                    </li>
                    <li>
                      Further indication of the success of the submission would
                      be helpful
                    </li>
                    <li>Was in line with expectations!</li>
                  </ul>
                </Card.Body>
              </Card>
              <Card>
                <iframe
                  title="User 3 video"
                  height="300"
                  src="https://www.youtube.com/embed/Q6TDsTGgdew"
                />
                <Card.Body>
                  <u>User 3</u>
                  <ul>
                    <li>
                      User initially attempted to navigate to the course through
                      the goals section, which was not interactive. They were
                      eventually able to navigate to lesson page and submit work
                    </li>
                    <li>
                      User was able to easily navigate to the scheduling page
                      and set up a meeting
                    </li>
                    <li>
                      User was able to easily navigate to the project ideas page
                      and look at project ideas
                    </li>
                    <li>
                      Improved highlighting and emphasis of in-progress courses
                      could help users quickly resume their work
                    </li>
                    <li>Was in line with expectations!</li>
                  </ul>
                </Card.Body>
              </Card>
            </CardGroup>
            <br />
            <p>
              <p className="subheading">Analysis</p>
            </p>
            <ul>
              <li>
                Users explicitly complimented the layout of the editor page and
                the goals sections. None of the users got stuck for any
                significant amount of time, and all of them were able to
                complete the tasks successfully.
              </li>
              <li>
                There were occasional moments of hesitation while users were
                determining which button was necessary to use for navigation.
              </li>
              <ul>
                <li>
                  This could be addressed by making the navigation buttons
                  prominently show which page they would be navigating to, and
                  by making the buttons use more visually distinct icons.{" "}
                </li>
                <li>
                  The user dashboard page could be improved by making the
                  in-progress courses more visually distinct to make it easier
                  for users to quickly resume a lesson.
                </li>
              </ul>
              <li>
                Users explicitly appreciated the breakdowns of content in
                modules, which made the content more approachable and inviting.
              </li>
              <ul>
                <li>
                  This strength could be further embraced by emphasizing the
                  same module structure on every page, and by bringing the
                  module layout to the syllabus button on the lesson editor
                  page.
                </li>
                <li>
                  The lesson editor page could have also benefited from a
                  clearer indication of the success of the submission, as users
                  were unsure of the status of their work.
                </li>
              </ul>
              <li>
                Overall, all the users were able to complete the tasks
                successfully, and the feedback was generally positive. In their
                post-test questionnaire, all the users rated the site as very
                likely to recommend to a friend or colleague (10, 9, and 7 out
                of 10 respectively).
              </li>
            </ul>
          </Container>
        </section>
      </div>
    </div>
  );
}

export default App;
